<template>
  <div class="vxe-column-settings">
    <slot>
      <el-button type="primary" icon="el-icon-s-operation" @click="drawer = true">显示列设置</el-button>
    </slot>
    <el-drawer
      class="vxe-columns-drawer"
      title="显示列设置"
      size="400px"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleDrawerClose"
    >
      <el-card class="ma-2 columns-warp pa-0">
        <template v-for="(column,index) in columns">
          <div v-if="column.title" :key="index" class="column-item ma-1 pa-2">
            <vxe-checkbox v-model="column.visible">{{ column.title }}</vxe-checkbox>
            <!-- <el-button-group class="columns-position">
              <el-button :type="column.fixed === 'left' ? 'primary' : 'default'" icon="el-icon-arrow-left" @click="column.fixed = 'left'" />
              <el-button :type="!column.fixed ? 'primary' : 'default'" icon="el-icon-close" @click="column.fixed = ''" />
              <el-button :type="column.fixed === 'right' ? 'primary' : 'default'" icon="el-icon-arrow-right" @click="column.fixed = 'right'" />
            </el-button-group> -->
          </div>
        </template>
      </el-card>
      <el-row class="columns-action">
        <el-col :span="12">
          <el-button size="medium" icon="el-icon-refresh-right" :loading="resetLoading" @click="handleResetColumn">还原</el-button>
        </el-col>
        <el-col :span="12">
          <el-button size="medium" icon="el-icon-check" type="primary" @click="handleLoadColumn">确认</el-button>
        </el-col>
      </el-row>
    </el-drawer>
  </div>

</template>

<script>
export default {
  name: 'VxeColumnSettings',
  inheritAttrs: true,
  props: {
    localKey: {
      type: String,
      default: ''
    },
    refName: {
      type: String,
      default: ''
    },
    containerRef: {
      type: Object,
      default: () => ({})
    },
    excludeList: {
      type: Array,
      default: () => []
    },
    defaultHidden: {
      type: Array,
      default: () => []
    },
    needList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      drawer: false,
      direction: 'rtl',
      localStorageColumns: [],
      columns: [],
      resetLoading: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      const columns = this.containerRef.getColumns()
      const localColumns = JSON.parse(localStorage.getItem(this.localKey))
      if (localColumns && (localColumns.length === columns.length || this.needList !== 0 || this.excludeList !== 0)) {
        this.columns = columns.map(item => {
          const sameItem = localColumns.find(e => item.title === e.title)
          if (sameItem) {
            item.visible = sameItem.visible
          }
          return item
        })
          .filter(item => !this.excludeList.includes(item.property))
        if (this.needList.length !== 0) {
          this.columns = this.columns.filter(item => this.needList.includes(item.property))
        }

        this.containerRef.refreshColumn()
      } else {
        this.columns = this.containerRef.getColumns().filter(item => !this.excludeList.includes(item.property))
        if (this.needList.length !== 0) {
          this.columns = this.columns.filter(item => this.needList.includes(item.property))
        }
        if (this.defaultHidden.length !== 0) {
          this.columns = this.columns.map(item => {
            if (this.defaultHidden.includes(item.property)) {
              item.visible = false
            }
            return item
          })
          this.containerRef.refreshColumn()
        }
      }
    })
  },
  methods: {
    handleDrawerClose() {
      this.drawer = false
    },
    async handleResetColumn() {
      try {
        this.resetLoading = true
        await this.containerRef.resetColumn()
        const columns = this.containerRef.getColumns()
        await this.containerRef.reloadColumn(columns)
        this.drawer = false
        this.$message.success('重置成功！')
        console.log(this.localKey)
        localStorage.removeItem(this.localKey)
      } finally {
        this.resetLoading = false
      }
    },
    handleLoadColumn() {
      this.containerRef.refreshColumn()
      this.drawer = false
      this.$message.success('设置成功！')
      console.log(this.columns)
      const JsonColumns = JSON.stringify(this.columns.map(item => ({ title: item.title, visible: item.visible, property: item.property })))
      localStorage.setItem(this.localKey, JsonColumns)
    },
    visibleShow() {
      this.drawer = true
    }
  }
}
</script>

<style lang="scss" >
// .vxe-columns-drawer .columns-warp .el-card__body {
//     padding: 0;
// }
.vxe-columns-drawer {
  .columns-warp {
    background: #ebeef5;
    max-height: calc(80vh - 80px);
    overflow: auto;
      .el-card__body {
        padding: 0 0 20px 0;
      }
      .columns-position .el-button {
        padding: 2px;
        font-size: 10px;
      }
    }
    .column-item {
      background: #fff;
      display: flex;
      justify-content: space-between;
  }
  .columns-action {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px;
    .el-col {
      display: flex;
      justify-content: center;
    }
    .el-button {
      width: 80%;
    }
  }
}
</style>
